import './presentation.scss'
import {ReactComponent as ImDevLogo} from '../../../logo.svg'

const Presentation = () => 
    <div className='presentation'>
        <div className="logo-box">
            <ImDevLogo />
        </div>
    </div>


export default  Presentation    